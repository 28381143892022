body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: black; */
  text-transform:lowercase;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  background-color: black;
  height: "100vh";
  width: "100vh";
}

.button-parent {
  text-align: center;
}

.button-parent .button {
  display: inline-block;
  text-transform: none;
  flex-direction: column;
  top: 620px;
  width: 282px;
  font-size: larger;
  position: sticky;
  margin-top: 90px;
}

#over img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.resize {
  width: 500px;
  height: auto;
}

.resize {
  width: 400px;
  height: auto;
}

.top-level { 
  height: 1000px;
    overflow: hidden;
    position: relative;
}

.host-text {
  font-size: x-large;
  color: white;
  text-transform: none;
  text-align: center;
}

.copy-button-parent {
  text-align: center;
  margin-top: 20px;
}

.copy-button-parent .copy-button {
  display: inline-block;
  text-transform: none;
  flex-direction: column;
  /* top: 620px;
  width: 282px; */
  font-size: larger;
  position: sticky;
  /* margin-top: 90px; */
}

.loading-screen-parent {
  text-align: center;
  margin-top: 20px;
}

.loading-screen-parent .loading {
  display: inline-block;
  text-transform: none;
  flex-direction: column;
  /* top: 620px;
  width: 282px; */
  font-size: larger;
  position: sticky;
  /* margin-top: 90px; */
}
